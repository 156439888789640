<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button type="primary"  @click="$refs.propertyDialog.setForm();$refs.propertyDialog.isShow = true">新增</a-button>
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="form"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>

      <a-form-item>
        <a-button @click="queryList" type="primary" html-type="submit">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm('form')">重置</a-button>
      </a-form-item>
    </a-form>

    <br/>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :childrenColumnName="Y">
      <div slot="action1"
           slot-scope="scope">
        <div>{{scope.name}}</div>
        <div>{{scope.code}}</div>
      </div>
      <div slot="unitCode"
           slot-scope="scope">
        {{scope.unitName}}
        {{scope.unitCode}}
      </div>
      <div slot="inputType"
           slot-scope="scope">
           {{
             scope.inputType==='string'?"字符串":
             scope.inputType==='radio'?"单选下拉":
             scope.inputType==='boolean'?"布尔":
             scope.inputType==='date'?"日期":
             scope.inputType==='number'?"数字":""
           }}
      </div>
      <div slot="required"
           slot-scope="scope">
        {{scope.required===0?"否":'是'}}
      </div>
      <div slot="propertyValues"
           slot-scope="scope">
        <span v-for="(item,index) in scope.item" :key="index" :value="item.id">
          <a-tag style="margin-top:5px; margin-bottom:5px;">{{item.name}}</a-tag>
        </span>
      </div>
      <span slot="action"
            slot-scope="app">
              <a @click="$refs.propertyDialog.setForm(app);$refs.propertyDialog.isShow = true">编辑</a>
        <a-divider type="vertical" />
        <a @click="del(app)">删除</a>
      </span>
    </a-table>
    <AddEditProperty ref="propertyDialog" @save="reloadList"></AddEditProperty>
  </div>
</template>

<script>
import AddEditProperty from './addOrEdit'
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: { AddEditProperty, Breadcrumb },
  data () {
    return {
      breadList: [
        {
          name: '属性设置',
          url: ''
        }
      ],
      Y: '', // 暂时不配置树形结构
      visible: false,
      doctorImag: '',
      form: {
        tenantId: undefined,
        id: '',
        name: '',
        sort: '',
        idPerent: '',
        iconUrl: '',
        goySpecificationsId: '',
        goyPropertysId: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'change' }
        ]
      },
      columns: [
        { title: '属性名称', width: 80, key: 'name', scopedSlots: { customRender: 'action1' } },
        { title: '是否必填', width: 50, key: 'required', scopedSlots: { customRender: 'required' } },
        { title: '输入方式', width: 80, key: 'inputType', scopedSlots: { customRender: 'inputType' } },
        { title: '单位', width: 80, key: 'unitCode', scopedSlots: { customRender: 'unitCode' } },
        { title: '属性值', width: 250, key: 'item', scopedSlots: { customRender: 'propertyValues' } },
        { title: '操作', key: 'operation', width: 200, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      showList: [],
      classList: [],
      total: 0,
      list: [],
      titleList: [],
      certificationStatusList: [],
      selectedRowKeys: [],
      hiddenProperty: '',
      visibilityChangeEvent: '',
      onVisibilityChange: null,
      isQueryLoading: false
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    // 删除
    del (app) {
      this.$store.dispatch('showConfirm', {
        message: '确认删除属性吗？',
        yes: async _ => {
          app.isLoading = true
          await this.$store.dispatch('http', {
            api: 'goyGoodsPropertyDelete',
            query: {
              id: app.id
            },
            complete: () => {
              app.isLoading = false
              this.$store.dispatch('showToast', { message: '删除属性成功~' })
              this.reloadList()
            }
          })
        }
      })
    },

    moment,
    onChange1 (date, dateString) {
      console.log('dateString: ', dateString)
      this.form.startTime = dateString[0] || ''
      this.form.endTime = dateString[1] || ''
    },
    handleSearch1 (value) {
      console.log('搜索的', value)
      this.yaoDianList(value)
    },
    // 重置表单
    resetForm () {
      this.form = {
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'getPropertyList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    zoom (scope) {
      this.visible = true
      this.doctorImag = scope
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
