<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增')+'属性'" @ok="determine" @cancel="cancel">
    <a-form-model ref="form" :rules="goyRules" :class="$style.form__ct" class="al-form"
                  :label-col="{ style: 'width: 150px' }" :wrapper-col="{ style: 'width: 300px'  }" layout="inline"
                  :model="form"
    style="">
        <a-form-model-item label="属性分类名称:" prop="name">
          <a-input v-model="form.name" :disabled="this.form.id !== undefined" type="string" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="属性分类编号:" prop="code">
          <a-input v-model="form.code" :disabled="this.form.id !== undefined" type="string" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="输入方式:" prop="inputType">
          <a-select style="width:100%;" v-model="form.inputType" @change="handleChange">
            <a-select-option v-for="item in inputTypeList" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="单位:" prop="unitCode">
          <a-select style="width:100%;" v-model="form.unitCode" allowClear @change="handleChangeUnit">
            <a-select-option v-for="item in unitCodeList" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否必填:" prop="required">
          <a-radio-group v-model="form.required">
            <a-radio :value="0">
              否
            </a-radio>
            <a-radio :value="1">
              是
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="排序:" prop="sort">
          <a-input v-model="form.sort" type="string" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item v-if="form.inputType ==='radio'" label="属性值(选填):" prop="goProperties">
          <a-select
            allowClear
            v-model="form.goPropertiesCopy"
            mode="tags"
            style="width: 100%"
            placeholder="请输入"
            aria-selected="true"
            @change="handleChangeSpecification"
          >
          </a-select>
        </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      copyForm: {},
      inputTypeList: [],
      unitCodeList: [],
      goyPropertyVOList: [], // 列表
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      app: {}, // 应用信息
      form: {
        id: '',
        code: this.$utils.codeGenerator('', 6),
        name: '',
        goProperties: []
      },
      tagProperty: [],
      tagSpecification: [],
      tags: [],
      inputVisible: false,
      inputValue: '',
      inputVisible2: false,
      inputValue2: '',
      goyRules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'change'
        }],
        required: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        code: [
          { required: true, message: '请填写属性编号', trigger: 'blur' },
          { min: 3, max: 50, message: '至少3个字符，最长50个字符', trigger: 'blur' }
        ],
        inputType: [
          { required: true, message: '请选择输入方式', trigger: 'blur' }
        ],
        goProperties: [
          { required: true, message: '请选择输入方式', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    handleChangeSpecification (e) {
      let e2 = []
      if (!this.form.goProperties) { this.form.goProperties = [] }
      this.form.goProperties.forEach(element => {
        e2.push(element.name)
      })

      let toAddVal = []
      e.forEach(element => {
        if (!e2.includes(element))toAddVal.push(element)
      })
      let toRemoveVal = []
      e2.forEach(element => {
        if (!e.includes(element))toRemoveVal.push(element)
      })

      toAddVal.forEach((item) => {
        this.form.goProperties.push({
          name: item
        })
      })
      toRemoveVal.forEach((item) => {
        this.form.goProperties.forEach((element, index) => {
          if (item === element.name) {
            this.form.goProperties.splice(index, 1)
          }
        })
      })
    },
    handleChange (value) {
      this.form.inputType = value
      console.log(`selected ${value}`)
    },
    handleChangeUnit (value) {
      console.log(value)
      if (value === undefined) {
        this.form.unitCode = undefined
        this.form.unitName = undefined
        return false
      }
      this.form.unitCode = value
      this.unitCodeList.forEach((item) => {
        if (item.code === value) {
          this.form.unitName = item.name
        }
      })
    },
    async getInputTypeList () {
      const res = await this.$store.dispatch('http', {
        api: 'inputTypeList'
      })
      this.inputTypeList = res
    },
    async getUniCodeList () {
      const res = await this.$store.dispatch('http', {
        api: 'unitItemList'
      })
      this.unitCodeList = res
      console.log(res)
    },
    // 设置表单
    setForm (form = {}) {
      // 获取输入方式
      this.getInputTypeList()
      this.getUniCodeList()
      const copyForm = this.$utils.clone(form)
      let { id, name, inputType, required, unitCode, unitName, code, item, sort } = { ...copyForm }
      let newPropertyList = []
      if (item) {
        for (const { name } of item) {
          newPropertyList.push(name)
        }
      }
      code = code || this.$utils.codeGenerator('', 6)
      this.form = {
        id, name, code, inputType, required: required || 0, unitCode, unitName, goProperties: item, goPropertiesCopy: newPropertyList, sort
      }
    },
    cancel () {
      this.$refs.form.resetFields()
      this.setForm()
    },
    // 提交保存
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          console.log(params, 'form的值')

          let api = 'goyGoodsPropertyAdd'
          if (params.id) api = 'goyGoodsPropertyUpdate'
          console.log('API等于：', api)
          console.log('表单内容：', params)
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '规格成功~' })
          this.isShow = false
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
